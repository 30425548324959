
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
@mixin centriraj {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-cont {
  width: 200px;
  height: 125px;
  background-color: pink;
  position: relative;
  margin: 2px;

  @media screen and (max-width: 400px) {
    height: 100px;
    width: 160px;
  }

  .img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .el {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.0);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 1s;
    @include centriraj;
    user-select: none;

    &:hover {
      background-color: rgba(0,0,0,0.6);
      cursor: pointer;

      .el-p {
        opacity: 1;
      }
    }

    .el-p {
      margin: 0px 2px 0px 2px;
      font-size: 14px;
      color: white;
      opacity: 0;
      transition: opacity 1s;
    }

    @media screen and (max-width: 450px) {
      & {
        height: 30px;
        background-color: rgba(0,0,0,0.6);
        top: initial;
        bottom: 0px;

        .el-p {
          opacity: 1;
        }
      }
    }
  }
}

.plast-auto {
    height: 100vh;
    width: 100vw;
    z-index: 5;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: none;  /*togglea izmedu none i flex*/
    justify-content: center;
    align-items: center;

    .modal-auto {
      /*height: 300px;
      width: 480px;*/
      height: 80vh;
      width: 80vw;
      overflow: auto;

      max-width: 90vw;
      background-color: #aaa;
      position: relative;
      @include centriraj;

      @media screen and (min-width: 1200px) {
            
        & {
          /*height: 400px;
          width: 640px;*/
        }
      }

      @media screen and (max-width: 550px) {
            
        & {
          /*height: 240px;
          width: 384px;*/
        }
      }

      @media screen and (max-width: 450px) {
            
        & {
          /*height: 200px;
          width: 320px;*/
        }
      }

      .model {
        position: absolute;
        bottom: 1%;
        left: 1%;

        position: fixed;
        bottom: 13vh;
        left: 10vw;

        height: 40px;
        width: 98%;
        width: 80vw;
        background-color: rgba(0,0,0,0.0);
        transition: background-color 1s;
        @include centriraj;

        .natpiss {
          font-size: 20px;
          color: white;
          opacity: 0;
          transition: opacity 1s;
        }

        @media screen and (max-width: 450px) {
          & {
            background-color: rgba(0,0,0,0.6);

            .natpiss {
              opacity: 1;
            }  
          }
        }

      }

      &:hover {
        .model {
          background-color: rgba(0,0,0,0.6);

          .natpiss {
            opacity: 1;
          } 
        } 
      }

      .img1 {
        /*height: 98%;
        width: 98%;*/
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        /*height: min(80vh, 80vw);
        width: calc(1.777 * min(80vh, 80vw));*/

        height: calc(40vh + 40vw);
        width: calc(1.777 * (40vh + 40vw));

        @media screen and (max-width: 720px) {
          & {
            height: calc(48vh + 48vw);
            width: calc(1.777 * (48vh + 48vw));
          }
        }
      }

      .krizic-okvir {
        position: fixed;
        right: 12vw;
        top: 12vh;

        @media screen and (max-width: 560px) {
          & {
              right: 14vw;
          }
        }

        @include centriraj;
        height: 24px;
        width: 24px;
        background-color: white;
        border-radius: 50%;
        transition: background-color 0.4s;
        z-index: 10;

        .krizic-auto {
          height: 70%;
          width: 70%;
          position: relative;
          /*background-color: white;*/
          transform: rotate(45deg);
        
          .el1a {
            position: absolute;
            height: 22%;
            width: 100%;
            top: 39%;
            left: 0%;
            background-color: black;
            transition: background-color 0.4s;
          }
    
          .el2a {
            position: absolute;
            height: 100%;
            width: 22%;
            top: 0%;
            left: 39%;
            background-color: black;
            transition: background-color 0.4s;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #bbb;

          .krizic-auto {
            .el1a, .el2a {
              background-color: #222;
            }
          }
        }
      }
    }
  }