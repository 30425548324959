$podloga: #dcf9fa;
$podloga: #baf3f5;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin centriraj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.kartica {
  height: 100px;
  width: 370px;
  max-width: 100%;
  background-color: #eee;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  user-select: none;
  transition: background-color 0.7s;
  

  &:hover {
    cursor: pointer;
    background-color: #ccc;

    .kartica-el3 {
      background-color: #ccc;
    }
  }

  .kartica-el1 {
    height: 100px;
    width: 30%;
    position: absolute;
    top: 0;
    left: 0;
    @include centriraj;
    /*background-color: red;*/
  
    .ikona {
      height: 50%;
      width: 50%;
      color: #68ede4;
    }
  }

  .kartica-el2 {
    position: absolute;
    /*background-color: blue;*/
    top: 0;
    right: 0;
    height: 100px;
    width: 70%;
    display: flex;
    align-items: center;

    .nas {
      font-size: 18px;
      color: black;
      margin: 5px;
    }
  }

  .kartica-el3 {
    position: absolute;
    top: 100px;
    left: 0;
    background-color: #eee;
    width: 100%;
    height: 400px;
    @include centriraj;
    transition: background-color 0.7s;

    .tekst {
      font-size: 14px;
      /*background-color: green;*/
      max-width: 90%;

      @media screen and (max-width: 450px) {
        & {
            font-size: 13px;
        }
      }
    }
  }
}

.glavna {
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  box-sizing: border-box;
  background-color: grey;
  border: 1px solid grey;
  position: relative;
  display: block; /*privremeno ucitavanja stranice*/

  .holder {
    position: relative;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $podloga;
  }

  .main {
    background-color: $podloga;
    box-sizing: border-box;
    border: 1px solid $podloga;

    .uvod {
      min-height: 300px;
      max-width: 85vw;
      width: 550px;
      background-color: #ddd;
      border-radius: 10px;
      margin: 50px auto 100px auto;
      @include centriraj;

      .uvod-tekst {
        /*background-color: green;*/
        max-width: 80%;
        margin: 30px 0px 30px 0px;
      }
      
    }

    .naslov-problemi {
      text-align: center;
      max-width: 90vw;
      margin: 0px auto 30px auto;
    }

    #naslov-video-prezentacija {
      margin: 40px auto 0px auto;
    }

    .video {
      max-height: 400px;
      max-width: 95vw;
      margin: 40px auto 50px auto;
      display: block;
    }

    .container-video1 {
      position: relative;
      overflow: hidden;
      /*max-width: 500px;
      max-height: 281px;*/
      max-width: 711px;
      max-height: 400px;
      margin: 40px auto 50px auto;
      /*padding-top: 56.25%;*/

      width: 70vw;
      height: 39.375vw;

      @media screen and (max-width: 450px) {
        & {
          width: 95vw;
          height: 53.44vw;

        }
      }

      .video1 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    .pain-points {
      width: 100vw;
      min-height: 500px;
      /*background-color: green;*/
      display: flex;
      flex-direction: row-reverse;

      .lijeva {
        min-height: 950px;
        width: 50%;
        /*background-color: yellow;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      .desna {
        min-height: 550px;
        width: 50%;
        /*background-color: red;*/
        position: relative;

        .slika-cont {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(52,235,58,0);
          @include centriraj;

          .slika-strana {
            max-height: 100%;
            width: 80%;
            opacity: 0;
            transition: opacity 2s;
          }

          #slika-strana-0 {
            opacity: 1;
          }

        }
      }

      @media screen and (max-width: 700px) {
        & {
            flex-direction: column;
            flex-direction: column-reverse;
            @include centriraj;

          .lijeva {
            width: 80%;
            min-height: 700px;
          }

          .desna {
            width: 80%;
          }
        }
      }
    }

    .screenshotovi {
      width: 100%;
      min-height: 150px;
      display: flex;
      justify-content: center;
      align-content: flex-start;
      flex-wrap: wrap;
      /*background-color: green;*/
    }

    .naslov {
      font-size: 36px;
      font-weight: 600;
      color: #444;
      /*background-color: red;*/
      text-align: center;
    }

    .naslov1 {
      font-size: 36px;
      font-weight: 600;
      color: #444;
      /*background-color: red;*/
      text-align: center;
    }

    .naslov2 {
      margin: 80px 0px 20px 0px;
    }

    .buffer1 {
      height: 50px;
      width: 100%;
      /*background-color: yellow;*/
    }

    .slika2 {
      width: 60%;
      height: 300px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("../public/slike/uber.png");
      margin: 20px auto 0px auto;

      @media screen and (min-width: 1250px) {
        & {
            height: 400px;
            width: 50%;
        }
      }

      @media screen and (max-width: 850px) {
        & {
            width: 70%;
        }
      }

      @media screen and (max-width: 620px) {
        & {
            height: 250px;
        }
      }

      @media screen and (max-width: 500px) {
        & {
            height: 200px;
        }
      }

      .plast {
        background-color: rgba(0,0,0,0);
        transition: background-color 0.7s;
        height: 100%;
        width: 100%;
        @include centriraj;

        .gumb {
          width: 200px;
          height: 70px;
          border: 2px solid white;
          border-radius: 5px;
          color: white;
          background-color: rgba(0,0,0,0);
          font-size: 20px;
          @include centriraj;
          user-select: none;
          transition: all 0.7s;

          &:hover {
            color: black;
            background-color: rgba(255,255,255,1);
            border: 2px solid rgba(255,255,255,1);
            cursor: pointer;
          }

          .gumb-p {
            text-decoration: none;
            font-size: 20px;
          }

          @media screen and (max-width: 630px) {
            & {
                height: 60px;
                width: 150px;

                .gumb-p {
                  font-size: 16px;
                }
            }
          }

          @media screen and (max-width: 500px) {
            & {
                height: 54px;
                width: 130px;

                .gumb-p {
                  font-size: 14px;
                }
            }
          }
        }

        &:hover {
          background-color: rgba(0,0,0,0.4);
        }
      }
    }

    .partneri {
      width: 100%;
      height: 150px;
      background-color: white;
      @include centriraj;

      @media screen and (max-width: 950px) {
        & {
            height: 100px;
        }
      }

      @media screen and (max-width: 650px) {
        & {
            height: 75px;
        }
      }

      @media screen and (max-width: 480px) {
        & {
            height: 50px;
        }
      }

      .logo-img {
        height: 100%;
      }
    }

    .el1 {
      width: 100%;
      min-height: 600px;
      background-color: $podloga;
      /*@include centriraj;*/
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .lijeva-ploca {
        min-height: 400px;
        width: 50%;
        /*background-color: red;*/

        @media screen and (max-width: 730px) {
          & {
              width: 70%;
          }
        }

        .slika1 {
          width: 100%;
          border-radius: 10% 20% 20% 0px;
          margin-top: 50px;
        }
        
        .slika2 {
          width: 100%;
          height: auto;
          border-radius: 10% 20% 20% 0px;
          margin-top: 20px;
  
        }

        .oglas-elektricni-bicikli {
          height: 100px;
          width: 90%;
          @include centriraj;
          background-color: #092c80;
          color: white;
          font-size: 30px;
          font-weight: 600;
          margin: 20px auto 0px auto;
          border-radius: 15px;

          @media screen and (max-width: 700px) {
            & {
                font-size: 25px;
            }
          }

          @media screen and (max-width: 600px) {
            & {
                font-size: 23px;
            }
          }

          .oglas-tekst {
            margin: 10px;
            text-align: center;
          }
        }
      }

      .ploca {
        min-height: 600px;
        width: 40%;
        background-color: #dcf9fa;
        flex-direction: column;
        @include centriraj;

        .lista1 {
          font-size: 15px;
          margin: 0px 5px 0px 5px;
        }

        .kartica-tekst {
          margin: 10px;
          font-size: 15px;
        }
      }

      @media screen and (max-width: 730px) {
        & {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .lijeva-ploca .slika2 {
              display: none;
            }

            .ploca {
              margin-top: 30px;
            }
        }
      }
    }
  }

  .slika-img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    transition: opacity 2s;
    display: block;

    @media screen and (min-width: 1100px) {
      & {
          height: 750px;
      }
    }

    @media screen and (max-width: 650px) {
      & {
          height: 450px;
      }
    }
  }

  #slika1-img {
    //content: url("../public/slike/uber.png");
    opacity: 1;
  }

  #slika2-img {
    position: absolute;
    top: 0;
    left: 0;
    //content: url("../public/slike/bolt.png");
    opacity: 0;
  }

  #slika3-img {
    position: absolute;
    top: 0;
    left: 0;
    //content: url("../public/slike/dostavljaci.jpg");
    opacity: 0;
  }

  .slika {
    width: 100%;
    height: 600px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: opacity 2s;

    @media screen and (min-width: 1100px) {
      & {
          height: 750px;
      }
    }

    @media screen and (max-width: 650px) {
      & {
          height: 450px;
      }
    }
  }

  #slika1 {
    background-image: url("../public/slike/uber.png");
    opacity: 1;
  }

  #slika2 {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../public/slike/bolt.png");
    opacity: 0;
  }

  #slika3 {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../public/slike/dostavljaci.jpg");
    opacity: 0;
  }

  .natpis {
    height: 600px;
    width: 65%;
    position: absolute;
    top: -20vh;
    left: 17.5%;
    /*background-color: rgba(246, 247, 178, 0.4);*/
    @include centriraj;
    flex-direction: column;
    transition: top 2s;

    @media screen and (min-width: 1100px) {
      & {
          height: 750px;
      }
    }

    @media screen and (max-width: 650px) {
      & {
          height: 450px;
          width: 90%;
          left: 5%;
      }
    }

    .pomak {
      height: 0;
      width: 0;
      @media screen and (max-width: 650px) {
        & {
            height: 100px;
            width: 100%;            
        }
      }
    }

    .natpis-el {
      font-size: 30px;
      font-weight: 600;
      font-family: sans-serif;
      color: white;
      text-align: center;
      /*display: inline-block;*/
      /*background-color: blue;*/
      transition: all 2s;

      @media screen and (max-width: 650px) {
        & {
            font-size: 27px;         
        }
      }

    }
  }
}



.rotirajuci {
  height: 60px;
  width: 500px;
  /*background-color: rgba(3,177,252,0.5);*/
  background-color: rgba(0,0,0,0.1);
  
  border-radius: 10px;;
  /*margin: 10px;*/
  /*display: inline-block;*/
  /*vertical-align: middle;*/
  position: relative;
  overflow: hidden;

  .element {
    height: 100%;
    width: 100%;
    @include centriraj;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    font-weight: 600;
    position: absolute;
    background-color: rgba(0,0,0,0.1);
    color: white;
    top: 0;
    left: 0;
    /*transition: opacity 1s, transform 1s;*/
    transition: opacity 0.5s 0.8s, transform 1s, color 1s;
  }

  @media screen and (max-width: 650px) {
    & {
        font-size: 27px;         
        height: 54px;
        width: 300px;
    }

    & .element {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .trenutni {
    opacity: 1;
    transform: translate(0%, 0%);
    z-index: 2;
  }
  .sljedeci {
    opacity: 1;
    transform: translate(100%, 0%);
  }
  .prethodni {
    opacity: 0;
    transform: translate(-100%, 0%);
  }
}


.razvojna {
  height: 100vh;
  width: 100vw;
  background-color: $podloga;
  border: 1px solid $podloga;
  box-sizing: border-box;
}