$visinaPolja: 80px;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
@mixin centriraj {
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq {
  background-color: white;
  max-width: 80%;
  min-height: $visinaPolja;
  margin: 30px auto 50px auto;
  background-color: white;
  border: black 2px solid;

  .naslov-pitanje {
    height: $visinaPolja;
    width: 100%;
    border-bottom: black 1px solid;
    /*border-top: red 1px solid;*/
    display: flex;
    align-items: center;
    background-color: #eee;
    transition: background-color 1s;

    &:hover {
        cursor: pointer;
    }
    
    .naslov-pitanje-p {
      margin: 0px 20px 0px 20px;
      user-select: none;
      transition: color 1s;
    }
  }

  .odgovor {
    /*min-height: $visinaPolja;*/
    width: 100%;
    border-bottom: black 1px solid;
    /*border-top: red 1px solid;*/
    display: flex;
    align-items: center;
    height: 0px;
    transition: height 1s;
    overflow: hidden;

    .odgovor-p {
      margin: 10px 20px 10px 20px;
    }
  }

}



